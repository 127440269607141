<template>
  <div>
    <ListModel
      :searchData="searchData"
      :tableData="tableData"
      :tableCols="tableCols"
      :formInline="formInline"
      :total="total"
      :pageSize="pageSize"
      :loading="loading"
      :pageNum.sync="pageNum"
      :tableOpt="tableOpt"
      :searchOpt="searchOpt"
      @select="selectRow"
      :selectOpt="selectOpt"
    >
      <template slot="searchCriteria">
        <el-form-item label="客服姓名">
          <el-input
            v-model.trim="formInline.monitorManagerName"
            style="width: 180px"
            placeholder="客服姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="监管平台帐户">
          <el-input
            v-model.trim="formInline.account"
            style="width: 180px"
            placeholder="监管平台帐户"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Employee_ID')">
          <el-input
            v-model.trim="formInline.monitorManagerCode"
            style="width: 180px"
            placeholder="员工编号"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.On_duty_status')">
          <el-select
            v-model="formInline.monitorManagerState"
            style="width: 180px"
            placeholder="在职状态"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option label="在职" value="1"> </el-option>
            <el-option label="离职" value="2"> </el-option>
          </el-select>
        </el-form-item>
      </template>
    </ListModel>
  </div>
</template>

<script>
import { jsMd5 } from "@/common/js/public.js";
import ListModel from "@/components/listModel/listModel";
export default {
  name: "customerManage",
  data() {
    return {
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      selectRows: [],
      tableCols: [
        {
          prop: "monitorManagerName",
          label: this.$t("list.full_name"),
          width: "90",
        },
        {
          prop: "monitorManagerRoleName",
          label: "所属角色",
          width: "",
        },
        {
          prop: "account",
          label: "监管平台账户",
          width: "",
        },
        {
          prop: "monitorManagerCode",
          label: this.$t("list.Employee_ID"),
          width: "",
        },
        {
          prop: "sex",
          label: "性别",
          width: "80",
          formatter: (row) => {
            return row.sex == "1" ? "男" : "女";
          },
        },
        {
          prop: "birthday",
          label: "年龄",
          width: "80",
          formatter: (row) => {
            return new Date().getFullYear() - row.birthday;
          },
        },
        {
          prop: "monitorManagerState",
          label: this.$t("list.On_duty_status"),
          width: "90",
          formatter: (row) => {
            return row.monitorManagerState == 1 ? "在职" : "离职";
          },
        },
        {
          prop: "createdTime",
          label: this.$t("list.add_time"),
          width: "",
        },
      ],
      // 搜索区域按钮
      searchOpt: [
        {
          type: "primary", // primary / success / warning / danger / info / text
          icon: "el-icon-search",
          label: "查询",
          loading: true,
          show: true,
          func: () => {
            this.pageNum = 1;
            this.searchData();
          },
        },
        {
          type: "primary", // primary / success / warning / danger / info / text
          icon: "el-icon-plus",
          label: "添加",
          show: this.$route.meta.authority.button.add,
          func: () => {
            this.$router.push("/customerManageAE");
          },
        },
      ],
      // 列表操作按钮
      tableOpt: {
        label: this.$t("list.operation"),
        width: "",
        type: "dropdown",
        show:
          this.$route.meta.authority.button.delete ||
          this.$route.meta.authority.button.edit ||
          this.$route.meta.authority.button.reset,
        operations: [
          {
            label: "修改",
            show: this.$route.meta.authority.button.edit,
            func: (scope) => {
              this.$router.push({
                path: "/customerManageAE",
                query: scope.row,
              });
            },
          },
          {
            label: "重置密码",
            show: this.$route.meta.authority.button.reset,
            func: this.resetPwd,
          },
          {
            label: "删除",
            show: this.$route.meta.authority.button.delete,
            func: this.delData,
          },
        ],
      },
      // 页脚操作按钮
      selectOpt: [
        // {
        //   type: "primary", // primary / success / warning / danger / info / text
        //   icon: "",
        // 	label: "重置密码",
        // 	func: this.resetPwd
        // }
      ],
      tableData: [],
      formInline: {
        monitorManagerName: "",
        account: "",
        monitorManagerCode: "",
        monitorManagerState: "",
      },
    };
  },
  methods: {
    resetPwd({ row }) {
      this.$confirm("此操作将重置密码为000000, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/monitorManager/resetpassword", {
              data: {
                monitorManagerId: row.monitorManagerId,
                password: jsMd5(row.account, "000000"),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "success",
                  message: "密码重置成功",
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置密码",
          });
        });
      // if (this.selectRows.length) {

      //   let opt = {
      //   url: "/boss/2.0/supervisor/resetPassword",
      //   method: 'post',
      //   data: {
      //     systemIds: this.selectRows.toString()
      //   },
      //   success: res => {
      //   this.loading = false;
      //   if (res.state == 0) {
      //     this.$message({
      //       type: "success",
      //       message: "密码重置成功"
      //     });
      //   } else {
      //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //         confirmButtonText: this.$t('pop_up.Determine')
      //       });
      //   }
      // }
      // };

      // }
    },
    selectRow(val) {
      val.forEach((element) => {
        this.selectRows.push(element.systemId);
      });
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/monitorManager/list", {
          data: {
            page: this.pageNum,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    delData({ row }) {
      this.$confirm("此操作将永久删除该条数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.post("/acb/2.0/monitorManager/delete/" + row.monitorManagerId).then((res) => {
            if (res.state == 0) {
              this.$alert("删除成功", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              }).then(() => {
                this.searchData();
              });
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  created() {},
  components: {
    ListModel,
  },
  activated() {
    this.searchData();
  },
};
</script>
